import { ApiClient, HttpMethods } from '@commonstock/client/src/constants'
import {
  getPersistedObjectState,
  setPersistedObjectState,
  usePersistedObjectState,
  storageObjectSubcription,
  setPersistedState
} from '@commonstock/common/src/utils/usePersistedState'

import { AuthTokensKey, AccountConfirmedKey } from '../scopes/auth/constants'
import { createFetch } from '@commonstock/client/src/createFetch'
import jwt_decode from 'jwt-decode'
import { MutexStub, Mutex } from '@commonstock/common/src/utils/mutex'
import sleep from '@commonstock/common/src/utils/sleep'
import { Apis } from '@commonstock/common/src/types/apis'
import { logoutAnalytics } from '../scopes/analytics/mixpanel'
import { ChatVisibilityKey, ChannelUrlKey, SendbirdAuthTokensKey } from '../scopes/chat/constants'
import { FeedStateKey } from '../scopes/feed/constants'
import { InitSessionTokenKey, InitRefreshTokenKey } from '../scopes/auth/constants'
import { SESSION_PREFIX } from '../config/constants'

export const AuthenticateRedirectKey = 'CS:AUTHENTICATED_REDIRECT'

const _mutex = initMutex()

function initMutex(): Promise<Mutex | MutexStub> {
  return new Promise(resolve => {
    if (typeof window === 'undefined' || typeof window.indexedDB === 'undefined') return new MutexStub()
    const db = window.indexedDB.open('test')
    db.onerror = function() {
      resolve(new MutexStub())
    }
    db.onsuccess = function() {
      resolve(new Mutex('refresh-token'))
    }
  })
}

type RefreshTokenPayload = {
  refresh_token: string
  access_token: string
}
type RefreshTokenParams = {
  json: {
    refresh_token: string
  }
}

export const postRefreshToken = createFetch<RefreshTokenPayload, RefreshTokenParams>({
  key: 'refresh-token',
  path: '/identity/v1/auth0/refresh-access',
  method: HttpMethods.Post,
  api: Apis.Auth
})

type RevokeTokenParams = {
  json: {
    refresh_token: string
  }
}
const postRevokeToken = createFetch<void, RevokeTokenParams>({
  key: 'revoke-token-key',
  path: '/identity/v1/auth0/revoke-refresh-token',
  method: HttpMethods.Post,
  api: Apis.Auth
})

type Tokens = {
  access: string
  refresh: string
}

let _tokens: Tokens | null = null
storageObjectSubcription<Tokens>(AuthTokensKey, tokens => {
  _tokens = tokens
})

// refresh if we have tokens AND they are old, OR if we cannot decode
function tokensNeedRefresh(earlyExpire: number): boolean {
  try {
    return !!_tokens?.access && jwt_decode<any>(_tokens.access).exp * 1000 < Date.now() + earlyExpire
  } catch (err) {
    console.log('## tokensNeedRefresh: Decode error', err)
    return true
  }
}

async function authenticator(client: ApiClient, earlyExpire = 5000) {
  _tokens = _tokens || (await getPersistedObjectState(AuthTokensKey))
  await refreshTokens(client, earlyExpire)
  return !_tokens
    ? undefined
    : {
        headers: {
          Authorization: `Bearer ${_tokens.access}`
        }
      }
}

const SPIN_DELAY = 1000
let _refreshing: ReturnType<typeof refreshTokens>
function refreshTokens(client: ApiClient, earlyExpire: number): undefined | Promise<void> {
  // nothing to do if tokens are fresh
  if (!tokensNeedRefresh(earlyExpire)) return
  return _mutex.then(mutex =>
    mutex
      .tryLock()
      .then(hasLock => {
        // nothing to do if we dont have tokens (may have been logged out while waiting for lock)
        if (!_tokens) return

        if (!hasLock) {
          // we are already refreshing in this process, wait for the result
          if (_refreshing) return _refreshing
          // probably some other process has the lock, sleep and then try again
          // @TODO should we do a max retry here?
          else return sleep(SPIN_DELAY).then(() => refreshTokens(client, earlyExpire))
        }

        _refreshing = postRefreshToken(client, { json: { refresh_token: _tokens.refresh } })
          .then(res => {
            res.success &&
              setPersistedObjectState(AuthTokensKey, {
                access: res.success.payload.access_token,
                refresh: res.success.payload.refresh_token
              })
          })
          .catch(err => {
            let isTerminal = err.fail?.status >= 400 && err.fail?.status < 500
            console.error(
              `## postRefreshToken: ${
                isTerminal ? 'Error is terminal, logging user out' : 'Error is not terminal, noop'
              }`,
              err
            )
            isTerminal && logout(client)
          })
          .finally(() => (_refreshing = undefined))
        return _refreshing
      })
      .catch(err => {
        console.error(`## couldnt lock mutex, probably due to other tab opened`, err)
      })
  )
}

function useAuthTokens(): [Tokens | null, (tokens: Tokens | null) => void, boolean] {
  return usePersistedObjectState<Tokens | null>(AuthTokensKey, null)
}

async function logout(client: ApiClient) {
  if (_tokens) await postRevokeToken(client, { json: { refresh_token: _tokens?.refresh } }).catch(() => {}) // noop on error, proceed with logout
  setPersistedObjectState(AuthTokensKey, null)
  setPersistedObjectState(AccountConfirmedKey, null)
  setPersistedState(ChatVisibilityKey, null)
  setPersistedState(ChannelUrlKey, null)
  setPersistedState(InitSessionTokenKey, null)
  setPersistedState(InitRefreshTokenKey, null)
  setPersistedState(FeedStateKey, null)
  setPersistedState(SendbirdAuthTokensKey, null)
  setPersistedState(AuthenticateRedirectKey, null)
  // purge anything starting with SESSION_PREFIX
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith(SESSION_PREFIX)) localStorage.removeItem(key)
  })

  // setTimeout to prevent any possible infinite loops of purge -> refetch -> purge
  setTimeout(() => {
    client.cache.purge()
    logoutAnalytics()
  }, 0)
}

export { authenticator, useAuthTokens, logout }
